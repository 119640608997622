<template>
  <v-select
    :items="budgetCodesWithLocation"
    label="Budget Code"
    v-model="budgetCode"
    v-bind="inputProps"
    return-object
    :loading="loading"
    clearable
    :disabled="$attrs.disabled || disabled"
    :persistent-hint="disabled"
    :hint="getHint"
  />
</template>

<script>
import { inputProps } from '@/util';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      inputProps: this.useInputProps ? inputProps : {},
      budgetCode: null,
      loading: false,
      disabled: false,
    };
  },
  watch: {
    budgetCode(val) {
      this.$emit('input', this.returnObject ? val : val?.text);
    },
  },
  props: {
    locationId: { type: Number, default: 0 },
    fundingSource: { type: Object, default: () => ({}) },
    useInputProps: { type: Boolean, default: true },
    returnObject: { type: Boolean, default: true },
    adminOnly: { type: Boolean, default: true },
    invoicePayment: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters('config', ['budgetCodeConfig']),
    ...mapGetters('budgetCode', ['budgetCodes']),
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('user', ['isAdmin', 'me']),
    ...mapGetters('tripRequest', ['canApproveCurrentTrip']),
    ...mapGetters('fundingSource', ['fundingSourcesById']),
    getHint() {
      if (!this.disabled) return undefined;

      if (this.adminOnly && !this.canApproveCurrentTrip)
        return 'You must be able to approve the current trip to select a budget code.';

      if (this.adminOnly && !this.isAdmin) return 'You must be an admin to select a budget code.';

      return undefined;
    },
    locationCode() {
      return this.locationsById[this.locationId]?.code || '';
    },
    budgetCodesWithLocation() {
      const fundingSource = this.fundingSourcesById[this.fsID];
      const type = fundingSource?.type;
      const index = this.budgetCodeConfig.findIndex((config) => config.locationDependent === true);

      let budgetCodes = [];
      if (type === 1) {
        if (fundingSource.budgetCode) {
          return [
            {
              value: null,
              text: this.replaceWithLocationCode(fundingSource.budgetCode),
            },
          ];
        } else {
          budgetCodes = this.budgetCodes.filter((bc) => Object.values(bc.code).join('').includes('?'));
        }
      } else if (type === 2) {
        if (fundingSource.budgetCode) {
          return [
            {
              value: null,
              text: fundingSource?.budgetCode,
            },
          ];
        } else {
          budgetCodes = this.budgetCodes.filter((bc) => !Object.values(bc.code).join('').includes('?'));
        }
      } else if (type === 3) {
        if (index !== -1) {
          budgetCodes = this.budgetCodes.filter((bc) => {
            const codes = Object.values(bc.code);
            const codesString = codes.join(' ');
            return !codesString.includes('?') && +codes[index] === +this.locationCode.toString();
          });
        }
        if (this.invoicePayment.budgetCode) {
          return [
            {
              value: null,
              text: this.invoicePayment.budgetCode,
            },
          ];
        }
      } else if (type === 4)
        return [
          {
            value: null,
            text: fundingSource?.budgetCodeDescription,
          },
        ];

      return budgetCodes.map((bc) => {
        return {
          value: bc.id,
          text: this.getDescription({
            budgetCode: bc,
            locationCode: this.locationCode,
            config: this.budgetCodeConfig,
          }),
        };
      });
    },
    fsID() {
      return this.fundingSource.id || this.fundingSource.fundingSourceId;
    },
  },
  methods: {
    ...mapActions('budgetCode', ['getBudgetCodes']),
    getDescription({ budgetCode, locationCode, config }) {
      const index = config.findIndex((c) => c.locationDependent === true);
      const budgetCodeValues = Object.values(budgetCode.code);

      if (index !== -1 && budgetCodeValues[index]?.includes('?')) budgetCodeValues[index] = locationCode;

      if (budgetCodeValues?.length > 0) return budgetCodeValues.join(' ');
      else return budgetCode.name;
    },
    replaceWithLocationCode(budgetCode) {
      return budgetCode.replace(/\?+/g, this.locationCode);
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getBudgetCodes();

      if (this.me.is.superAdmin) return;

      if (this.adminOnly && !this.isAdmin) this.disabled = true;
      if (this.adminOnly && !this.canApproveCurrentTrip) this.disabled = true;
    } catch (e) {
      this.$myalert.error('An unexpected error occurred while processing your request. Please try again later.', true);
    } finally {
      this.loading = false;
    }
  },
};
</script>
