export const fundingSourceTypesMap = {
  singleBudgetCode: 1,
  singleBudgetCodeAllotments: 2,
  multipleBudgetCodes: 3,
  editableBlank: 4,
};

export const singleBudgetCodes = [
  fundingSourceTypesMap.singleBudgetCode,
  fundingSourceTypesMap.singleBudgetCodeAllotments,
];
