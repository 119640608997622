/**
 * Mixin to download attachments from the server
 * This has `isDownloadOngoing` data property to track download status that can be used to show/hide download button
 *
 */
export const downloadAttachmentMixin = {
  data() {
    return {
      isDownloadOngoing: false,
    };
  },
  methods: {
    /**
     * Download attachment from the server
     * @param {Function} callback - Function to call to download the attachment
     * @param {String} fileName - Name of the file on successful download
     */
    downloadAttachment(callback, fileName) {
      this.isDownloadOngoing = true;
      callback()
        .then((res) => {
          const href = URL.createObjectURL(res);

          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((err) => {
          this.$myalert.error(`Error downloading file. ${err.message}`);
        })
        .finally(() => {
          this.isDownloadOngoing = false;
        });
    },
  },
};
